import React, { useState } from 'react'
import {
  DrupalRegister,
  DrupalAuthenticationProvider,
  DrupalLogin,
} from 'react-drupal'
import Layout from '../components/Layout'
import GlobalClient from 'drupal-jsonapi-client/lib/GlobalClient'
import { navigate } from 'gatsby'
import idx from 'idx'
import { Entity, Filter, User } from 'drupal-jsonapi-client'
import '../../base.css'
import Seo from '../components/Seo'
import './styles/register.css'

const safeNavigate = path => {
  if (typeof window !== `undefined`) {
    navigate(path)
  }
}

const Register = ({ location }) => {
  GlobalClient.baseUrl = 'https://dev-skillsailors.pantheonsite.io'
  const voyageTitle = idx(location, _ => _.state.voyageTitle)
  const to = idx(location, _ => _.state.from)
  const title = idx(location, _ => _.state.voyageTitle)
  const [form, setForm] = useState('register')

  if (!voyageTitle) {
    safeNavigate('/admin')
  }

  const onCreateSession = async (user, session) => {
    GlobalClient.authorization = `Bearer ${session.jwt}`

    const users = await Entity.LoadMultiple({
      entityType: 'user',
      entityBundle: 'user',
      filter: new Filter({
        identifier: 'ids',
        path: 'uid',
        value: user.current_user.uid,
      }),
    })

    return {
      ...session,
      user: {
        data: { ...users[0]._serialize().data, id: users[0].entityUuid },
      },
    }
  }

  const handleChangeSession = ({ jwt }) => {
    GlobalClient.authorization = jwt ? `Bearer ${jwt}` : null

    if (jwt) {
      safeNavigate(to)
    }
  }

  return (
    <Layout>
      <Seo title="Register" />
      <DrupalAuthenticationProvider
        onInit={({ jwt }) => {
          GlobalClient.authorization = jwt ? `Bearear ${jwt}` : null
          if (jwt) {
            safeNavigate(to)
          }
        }}
        onChange={handleChangeSession}
      >
        {() => {
          return (
            <React.Fragment>
              <div className="py-8">
                <p className="text-xl m-0 text-gray-700 font-bold">
                  Register to send the captain a request to join:
                </p>
                <h3 className="text-4xl py-2 font-bold">
                  {title}
                </h3>
              </div>

              <div className="flex flex-col md:flex-row md:justify-center">
                <div className="w-full relative  my-4 p-4 md:w-1/2 md:my-0 register-block shadow register-margin-r">
                  <div>
                    <ul className="pl-4 list-disc">
                      <img className="register-image" alt="Register to skillsailors" src="/sailor.png"/>
                      <div className="register-item__wrapper">
                        <img className="register-icon" alt="Access to all voyages offered on Skill Sailors" src="/checked.svg"/>
                        <div className="">Access to all voyages offered on Skill Sailors</div>
                      </div>
                      <div className="register-item__wrapper">
                        <img className="register-icon" alt="30 min video session with a travel mentor from our team to help set up or find a voyage that works for you." src="/checked.svg"/>
                        <div className="">30 min video session with a travel mentor from our team to help set up or find a voyage that works for you.</div>
                      </div>
                      <div className="register-item__wrapper">
                        <img alt="Access to private SkillSailors Slack channel<" className="register-icon" src="/checked.svg"/>
                        <div>Access to private SkillSailors Slack channel</div>
                      </div>
                      <div className="register-item__wrapper">
                        <img alt="Recommendations, tips and suggestions for traveling through different countries" className="register-icon" src="/checked.svg"/>
                        <div>Recommendations, tips and suggestions for traveling through different countries</div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="column register-block shadow p-4 register-margin-l">
                    {form === 'register' ? (
                      <React.Fragment>
                        <DrupalRegister
                          onLogin={User.Login}
                          onRegister={User.Register}
                          onCreateSession={onCreateSession}
                          expireAfterMinutes={60}
                        />
                      <p className="pt-8 m-0">
                          Already have a SkillSailors account?{' '}
                          <button
                            onClick={() => {
                              setForm('login')
                            }}
                            className="underline"
                          >
                            Log in
                          </button>
                        </p>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <DrupalLogin
                          onLogin={User.Login}
                          onRegister={User.Register}
                          onCreateSession={onCreateSession}
                          expireAfterMinutes={60}
                        />
                        <p className="pt-4 m-0">
                          Don’t have an account?{' '}
                          <button
                            onClick={() => {
                              setForm('register')
                            }}
                            className="underline"
                          >
                            Sign up
                          </button>
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </DrupalAuthenticationProvider>
    </Layout>
  )
}

export default Register
